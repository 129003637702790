import {constructByInterface} from '../../utils/construct-by-interface';
import {DiseaseInterface, DiseaseModel} from './disease.model';
import {ManufacturerInterface, ManufacturerModel} from './manufacturer.model';
import {VaccineSeriesInterface, VaccineSeriesModel} from './vaccine-series.model';
import {propertyesType, PropertyViewType} from '../../modules/shared/decorators/property-type.decorator';

export interface VaccineInterface {
  id: number;
  parent: VaccineInterface;
  children: Array<VaccineInterface>;
  name: string;
  vaccineSeries: Array<VaccineSeriesInterface>;
  manufacturer: ManufacturerInterface;
  diseases: Array<DiseaseInterface>;
  invalid: boolean;
  activityDuration: number;
}

export class VaccineModel implements VaccineInterface {
  id: number;
  parent: VaccineModel;
  children: Array<VaccineModel>;
  @propertyesType({type: PropertyViewType.INPUT_STRING, title: 'Наименование', col: 12, required: true})
  name: string;
  @propertyesType({type: PropertyViewType.INPUT_STRING, title: 'Серия вакцин',  col: 12, required: true})
  vaccineSeries: Array<VaccineSeriesModel>;
  @propertyesType({type: PropertyViewType.INPUT_STRING, title: 'Производитель', col: 12, required: true})
  manufacturer: ManufacturerModel;
  @propertyesType({type: PropertyViewType.INPUT_STRING, title: 'Заболевания', col: 12, required: true})
  diseases: Array<DiseaseModel>;
  invalid: boolean;
  @propertyesType({type: PropertyViewType.INPUT_INT, title: 'Продолжительность деятельности', col: 12, required: true})
  activityDuration: number;

  constructor(o?: VaccineInterface) {
    constructByInterface(o, this);
  }
}
