import {constructByInterface} from '../../utils/construct-by-interface';
import {BreedInterface, BreedModel} from './breed.model';

export interface KindInterface {
  id: string;
  parent: KindInterface;
  children: Array<KindInterface>;
  name: string;
  breeds: Array<BreedInterface>;
  isInvalid: boolean;
}

export class KindModel implements KindInterface {
  id: string;
  parent: KindModel;
  children: Array<KindModel>;
  name: string;
  breeds: Array<BreedModel>;
  isInvalid: boolean;

  constructor(o?: KindInterface) {
    constructByInterface(o, this);
  }
}
