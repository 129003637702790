<mat-form-field appearance="fill">
  <mat-label>{{label}}</mat-label>
  <input type="text" class="autocomplete-input" matInput [placeholder]="placeholder" [formControl]="control" [matAutocomplete]="auto"
          (focus)="onFocus()" [required]="required" [disabled]="disabled"/>
  <k-icon matPrefix name="search" [style.color]="'black'" size="small" color="black"></k-icon>
  <div class="icon-container" *ngIf="loading">
    <i class="loader"></i>
  </div>
  <mat-autocomplete #auto="matAutocomplete" >
    <div class="mat-option-loading" *ngIf="loading"></div>
    <mat-option *ngFor="let option of filteredOptions" [value]="option" (click)="onSelected()">
      {{option}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
