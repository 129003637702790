import {constructByInterface} from '../../utils/construct-by-interface';
import {CountryInterface} from './country.model';
import {VaccineInterface} from './vaccine.model';
import {propertyesType, PropertyViewType} from '../../modules/shared/decorators/property-type.decorator';

export interface ManufacturerInterface {
  id: string;
  name: string;
  country: CountryInterface;
  vaccines: Array<VaccineInterface>;
}

export class ManufacturerModel implements ManufacturerInterface {
  id: string;
  @propertyesType({type: PropertyViewType.INPUT_STRING, title: 'Наименование', col: 12, required: true})
  name: string;
  @propertyesType({type: PropertyViewType.INPUT_STRING, title: 'Страна', col: 12, required: true})
  country: CountryInterface;
  @propertyesType({type: PropertyViewType.INPUT_STRING, title: 'Вакцинации', col: 12, required: true})
  vaccines: Array<VaccineInterface>;

  constructor(o?: ManufacturerInterface) {
    constructByInterface(o, this);
  }
}
