import {constructByInterface} from '../../utils/construct-by-interface';
import {propertyesType, PropertyViewType} from '../../modules/shared/decorators/property-type.decorator';

export interface ReferenceStationInterface {
  id: number;
  name: string;
  parent: ReferenceStationModel;
  deleted: boolean;
}

export class ReferenceStationModel {
  id: number;
  @propertyesType({type: PropertyViewType.INPUT_STRING, title: 'Наименование', col: 12, required: true})
  name: string;
  @propertyesType({type: PropertyViewType.AUTOCOMPLETE, col: 12, required: true, crudType: 'ReferenceStation', label: 'station'})
  parent: ReferenceStationModel;
  deleted: boolean;

  constructor(o?: ReferenceStationInterface) {
    constructByInterface(o, this);
  }
}
