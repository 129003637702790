import {constructByInterface} from '../../../utils/construct-by-interface';
import {ReportsListModel} from './reports-list.model';

export interface DataReportsInterface {
  id: string;
  reports: ReportsListModel;
  stationId: string;
  statusId: number;
  year: number;
  quarter: number;
  month: number;
  data: string;

}

export class DataReportsModel implements DataReportsInterface {
  id: string;
  reports: ReportsListModel;
  stationId: string;
  statusId: number;
  year: number;
  quarter: number;
  month: number;
  // TODO если в дату прилетает json то нужна структура для него , что бы создать модель
  data: string;

  constructor(o?: DataReportsInterface) {
    constructByInterface(o, this);
  }
}
