import {constructByInterface} from '../../utils/construct-by-interface';
import {AnimalInterface, AnimalModel} from '../animal/animal.model';
import {PersonInterface, PersonModel} from '../auth/person.model';
import {SupervisoryAuthorityInterface, SupervisoryAuthorityModel} from '../contractors/supervisory-authority.model';
import {VaccineSeriesInterface, VaccineSeriesModel} from '../dictionary/vaccine-series.model';
import {EnumTypeInterface, EnumTypeModel} from '../enum-type.model';

export interface VaccinationInterface {
  id: number;
  animals: Array<AnimalInterface>;
  vaccineSeries: Array<VaccineSeriesInterface>;
  date: string;
  createdBy: PersonInterface;
  financingType: EnumTypeInterface;
  vaccinationType: EnumTypeInterface;
  station: SupervisoryAuthorityInterface;
  people: Array<PersonInterface>;
}

export class VaccinationModel implements VaccinationInterface {
  id: number;
  animals: Array<AnimalModel>;
  vaccineSeries: Array<VaccineSeriesModel>;
  date: string;
  createdBy: PersonModel;
  financingType: EnumTypeModel;
  vaccinationType: EnumTypeModel;
  station: SupervisoryAuthorityModel;
  people: Array<PersonModel>;

  constructor(o?: VaccinationInterface) {
    constructByInterface(o, this);
  }
}

