import {constructByInterface} from '../../utils/construct-by-interface';

export interface ColourInterface {
  id: string;
  parent: ColourInterface;
  children: Array<ColourInterface>;
  name: string;
  isInvalid: boolean;
}

export class ColourModel implements ColourInterface {
  id: string;
  parent: ColourInterface;
  children: Array<ColourInterface>;
  name: string;
  isInvalid: boolean;

  constructor(o?: ColourInterface) {
    constructByInterface(o, this);
  }
}
