import {constructByInterface} from '../../utils/construct-by-interface';
import {KindInterface} from './kind.model';
import {propertyesType, PropertyViewType} from '../../modules/shared/decorators/property-type.decorator';

export interface BreedInterface {
  id: string;
  name: string;
  isInvalid: boolean;
  createdAt: string;
  updatedAt: string;
}

export class BreedModel implements BreedInterface {
  id: string;
  @propertyesType({type: PropertyViewType.INPUT_STRING, title: 'Наименование', col: 12, required: true})
  name: string;
  isInvalid: boolean;
  createdAt: string;
  updatedAt: string;

  constructor(o?: BreedInterface) {
    constructByInterface(o, this);
  }
}
