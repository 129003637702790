import {constructByInterface} from '../../utils/construct-by-interface';
import {propertyesType, PropertyViewType} from '../../modules/shared/decorators/property-type.decorator';

export interface ReferenceIconInterface {
  id?: number;
  name: string;
  deleted: boolean;
  class: string;
  code: string;
}

export class ReferenceIconModel implements ReferenceIconInterface {
  id: number;
  @propertyesType({type: PropertyViewType.INPUT_STRING, title: 'Наименование', col: 12, required: true})
  name: string;
  deleted: boolean;
  @propertyesType({type: PropertyViewType.INPUT_STRING, title: 'Класс', col: 12})
  class: string;
  @propertyesType({type: PropertyViewType.INPUT_STRING, title: 'Код', col: 12, required: true})
  code: string;

  constructor(o?: ReferenceIconInterface) {
    constructByInterface(o, this);
  }
}
