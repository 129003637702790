import {constructByInterface} from '../../utils/construct-by-interface';
import {UserInterface, UserModels} from '../user/user.models';
import {propertyesType, PropertyViewType} from '../../modules/shared/decorators/property-type.decorator';


export interface ReferenceBusinessEntityInterface {
  id: number;
  name: string;
  legalForms: {
    code: string;
    title: string;
  };
  inn: string;
  kpp: string;
  ogrn: string;
  bik: string;
  legalAddres: string;
  headFullName: string;
  headOffice: string;
  website: string;
  registrationDate: string;
  liquidationDate: string;
  comment: string;
  workingWithSocialObj: boolean;
  user: UserInterface;
  bank: string;
  planMonth: string;
  planSkipYear: string;
  checkingAccount: string;
  corAccount: string;
  businessSize: string;
  lastCheck: number;
  riskPoints: number;
}

export class ReferenceBusinessEntityModel implements ReferenceBusinessEntityInterface {
  id: number;
  @propertyesType({type: PropertyViewType.INPUT_STRING, title: 'Наименование', col: 12, required: true})
  name: string;
  @propertyesType({type: PropertyViewType.SELECT, title: 'Правовые формы', col: 4, required: true, crudType: 'LegalFormsEnum'})
  legalForms: {
    code: string;
    title: string;
  };
  @propertyesType({type: PropertyViewType.INPUT_STRING,  title: 'ИНН', col: 2, required: true})
  inn: string;
  @propertyesType({type: PropertyViewType.INPUT_STRING, title: 'КПП', col: 2, required: false})
  kpp: string;
  @propertyesType({type: PropertyViewType.INPUT_STRING,  title: 'ОГРН', col: 2, required: false})
  ogrn: string;
  @propertyesType({type: PropertyViewType.INPUT_STRING, title: 'БИК', col: 2, required: false})
  bik: string;
  @propertyesType({type: PropertyViewType.INPUT_STRING, title: 'Юридический адрес (улица, дом, офис)', col: 12, required: true})
  legalAddres: string;
  @propertyesType({type: PropertyViewType.DADATA_FULL_NAME,  title: 'ФИО руководителя', col: 12, required: true})
  headFullName: string;
  @propertyesType({type: PropertyViewType.INPUT_STRING,  title: 'Должность', col: 4, required: false})
  headOffice: string;
  @propertyesType({type: PropertyViewType.INPUT_STRING, title: 'Сайт', col: 4, required: false})
  website: string;
  @propertyesType({type: PropertyViewType.DATE, title: 'Дата регистрации', col: 2, required: false})
  registrationDate: string;
  @propertyesType({type: PropertyViewType.DATE,  title: 'Дата ликвидации', col: 2, required: false})
  liquidationDate: string;
  @propertyesType({type: PropertyViewType.INPUT_STRING, title: 'Комментарии', col: 12, required: false})
  comment: string;
  @propertyesType({type: PropertyViewType.CHECK_BOX, col: 6, required: false, label: 'Объект использует форму электронной сертификации'})
  workingWithSocialObj: boolean;
  @propertyesType({type: PropertyViewType.AUTOCOMPLETE, title: 'Пользователь', col: 12, required: false, crudType: 'User'})
  user: UserModels;
  bank: string;
  planMonth: string;
  planSkipYear: string;
  checkingAccount: string;
  corAccount: string;
  businessSize: string;
  lastCheck: number;
  riskPoints: number;


  constructor(o?: ReferenceBusinessEntityInterface) {
    if (o) {
      constructByInterface(o, this);
    }
  }
}
