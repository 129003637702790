import {constructByInterface} from '../../utils/construct-by-interface';
import {KindDiseaseRelationInterface, KindDiseaseRelationModel} from './kind-disease-relation.mode';
import {VaccineInterface, VaccineModel} from './vaccine.model';
import {propertyesType, PropertyViewType} from '../../modules/shared/decorators/property-type.decorator';

export interface DiseaseInterface {
  id: number;
  name: string;
  vaccines: Array<VaccineInterface>;
  kindDiseaseRelations: Array<KindDiseaseRelationInterface>;
  createdAt: string;
  isInvalid: boolean;
  show: boolean;
  updatedAt: string;
}

export class DiseaseModel implements DiseaseInterface {
    id: number;
    @propertyesType({type: PropertyViewType.INPUT_STRING, title: 'Наименование', col: 12, required: true})
    name: string;
    @propertyesType({type: PropertyViewType.INPUT_STRING, title: 'Вакцинации', col: 12, required: true})
    vaccines: Array<VaccineModel>;
    kindDiseaseRelations: Array<KindDiseaseRelationModel>;
    createdAt: string;
    isInvalid: boolean;
    show: boolean;
    updatedAt: string;

  constructor(o?: DiseaseInterface) {
    constructByInterface(o, this);
  }
}
