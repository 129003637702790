import {constructByInterface} from '../../utils/construct-by-interface';
import {CircleInterface, CircleModel} from './circle.model';
import {PathInterface, PathModel} from './path.model';

export interface LocationInterface {
  id: string;
  parent: LocationInterface;
  name: string;
  address: string;
  fiasId: string;
  regionFiasId: string;
  areaFiasId: string;
  cityFiasId: string;
  cityDistrictFiasId: string;
  settlementFiasId: string;
  streetFiasId: string;
  houseFiasId: string;
  paths: Array<PathInterface>;
  circles: Array<CircleInterface>;
}

export class LocationModel implements LocationInterface {
  id: string;
  parent: LocationModel;
  name: string;
  address: string;
  fiasId: string;
  regionFiasId: string;
  areaFiasId: string;
  cityFiasId: string;
  cityDistrictFiasId: string;
  settlementFiasId: string;
  streetFiasId: string;
  houseFiasId: string;
  paths: Array<PathModel>;
  circles: Array<CircleModel>;

  constructor(o?: LocationInterface) {
    constructByInterface(o, this);
  }
}
