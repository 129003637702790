import {constructByInterface} from '../../utils/construct-by-interface';
import {AnimalStampTypeInterface, AnimalStampTypeModel} from '../dictionary/animal-stamp-type.model';
import {AnimalInterface, AnimalModel} from './animal.model';
import {propertyesType, PropertyViewType} from '../../modules/shared/decorators/property-type.decorator';

export interface AnimalStampInterface {
  id: string;
  name: string;
  stampDate: string;
  isCurrent: boolean;
  animal: AnimalInterface;
  createdAt: string;
  updatedAt: string;
}

export class AnimalStampModel implements AnimalStampInterface {
  id: string;
  @propertyesType({type: PropertyViewType.INPUT_STRING, col: 12, required: true})
  name: string;
  stampDate: string;
  isCurrent: boolean;
  animal: AnimalModel;
  createdAt: string;
  updatedAt: string;

  constructor(o?: AnimalStampInterface) {
    constructByInterface(o, this);
  }
}
