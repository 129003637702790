import {constructByInterface} from '../../utils/construct-by-interface';

export interface ActionInterface {
  name: string;
  code: string;
  description: string;

  parentId: number;
  groups: any;
  sort: number;

  type: {
    code: string;
    title: string;
  };
  url: string;
  entityClass: string; // 'App\\Entity\\Security\\Role'

  buttonSettings: {
    color: string;
    backgroundColor: string;
  };
  confirmation: {
    title: string;
    description: string;
    confirmButtonSettings: {
      color: string;
      backgroundColor: string;
    }
    cancelButtonSettings: {
      color: string;
      backgroundColor: string;
    }
  };

  itemsCountEnabled: boolean;
  itemsCount: number;

  getListEnabled: boolean;
  viewItemEnabled: boolean;
  getItemEnabled: boolean;
}

export class ActionModel {
  name: string;
  code: string;
  description: string;

  parentId: number;
  groups: any;
  sort: number;

  type: {
    code: string;
    title: string;
  };
  url: string;
  entityClass: string; // 'App\\Entity\\Security\\Role'

  buttonSettings: {
    color: string;
    backgroundColor: string;
  };
  confirmation: {
    title: string;
    description: string;
    confirmButtonSettings: {
      color: string;
      backgroundColor: string;
    }
    cancelButtonSettings: {
      color: string;
      backgroundColor: string;
    }
  };

  itemsCountEnabled: boolean;
  itemsCount: number;
  items: [];

  getListEnabled: boolean;
  viewItemEnabled: boolean;
  getItemEnabled: boolean;

  constructor(o?: ActionInterface) {
    constructByInterface(o, this);
  }
}
