import {constructByInterface} from '../../utils/construct-by-interface';
import {propertyesType, PropertyViewType} from '../../modules/shared/decorators/property-type.decorator';

export interface ReportExplanatoryNoteInterface {
  id: number;
  comment: string;
  file: boolean;
  userName: number;
  reportData: number;
  deleted: number;
  createdAt: number;
  uploadedAt: number;
}

export class ReportExplanatoryNoteModel implements ReportExplanatoryNoteInterface {
  id: number;
  @propertyesType({type: PropertyViewType.INPUT_STRING, title: 'Наименование', col: 12, required: true})
  comment: string;
  file: boolean;
  userName: number;
  reportData: number;
  deleted: number;
  createdAt: number;
  uploadedAt: number;


  constructor(o?: ReportExplanatoryNoteInterface) {
    constructByInterface(o, this);
  }
}
