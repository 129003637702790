import {LocationInterface, LocationModel} from 'src/app/models/location/location.model';
import {constructByInterface} from '../../utils/construct-by-interface';
import {BreedInterface, BreedModel} from '../dictionary/breed.model';
import {ColourInterface, ColourModel} from '../dictionary/colour.model';
import {KindInterface, KindModel} from '../dictionary/kind.model';
import {VaccinationInterface, VaccinationModel} from '../vaccination/vaccination.model';
import {AnimalStampInterface, AnimalStampModel} from './animal-stamp.model';
import {propertyesType, PropertyViewType} from '../../modules/shared/decorators/property-type.decorator';

export interface AnimalInterface {
  id: number;
  name: string;
  kind: KindInterface;
  breed: BreedInterface;
  colour: ColourInterface;
  location: LocationInterface;
  birthdate: string;
  owner: string;
  chip: string;
  animalStamps: Array<AnimalStampInterface>;
  vaccinations: Array<VaccinationInterface>;
  createdAt: string;
  updatedAt: string;
}

export class AnimalModel implements AnimalInterface {
  id: number;
  @propertyesType({type: PropertyViewType.INPUT_STRING, title: 'Наименование', col: 12, required: true})
  name: string;
  @propertyesType({type: PropertyViewType.AUTOCOMPLETE, col: 4, title: 'Вид', required: true, crudType: 'DictionaryKind'})
  kind: KindModel;
  @propertyesType({type: PropertyViewType.AUTOCOMPLETE, col: 4, title: 'Порода', required: true, crudType: 'DictionaryBreed'})
  breed: BreedModel;
  @propertyesType({type: PropertyViewType.AUTOCOMPLETE, col: 4, title: 'Цвет', required: false, crudType: 'DictionaryColour'})
  colour: ColourModel;
  @propertyesType({type: PropertyViewType.AUTOCOMPLETE, col: 6, title: 'Местоположение', required: true, crudType: 'DictionaryAnimalLivingPlaces'})
  location: LocationModel;
  @propertyesType({type: PropertyViewType.DATE, col: 6, title: 'Дата рождения', required: false})
  birthdate: string;
  @propertyesType({type: PropertyViewType.DADATA_FULL_NAME, col: 12, title: 'Хозяин', required: true})
  owner: string;
  @propertyesType({type: PropertyViewType.INPUT_STRING, col: 4, title: 'Чип', required: false})
  chip: string;
  @propertyesType({type: PropertyViewType.AUTOCOMPLETE, col: 8, title: 'Бирка', required: false, crudType: 'DictionaryAnimalStamps'})
  animalStamps: Array<AnimalStampModel>;
  @propertyesType({type: PropertyViewType.AUTOCOMPLETE, col: 12, title: 'Вакцинации', required: false, crudType: 'DictionaryVaccine'})
  vaccinations: Array<VaccinationModel>;
  createdAt: string;
  updatedAt: string;

  constructor(o?: AnimalInterface) {
    constructByInterface(o, this);
  }
}
