import {LocationInterface, LocationModel} from 'src/app/models/location/location.model';
import {constructByInterface} from '../../utils/construct-by-interface';
import {CountryInterface, CountryModel} from '../dictionary/country.model';
import {propertyesType, PropertyViewType} from '../../modules/shared/decorators/property-type.decorator';

export interface AnimalLivingPlaceInterface {
  id: string;
  arrivalDate: string;
  country: CountryInterface;
  location: LocationInterface;
  address: string;
  isCurrent: boolean;
  note: string;
  createdAt: string;
  updatedAt: string;
}

export class AnimalLivingPlaceModel implements AnimalLivingPlaceInterface {
  id: string;
  @propertyesType({type: PropertyViewType.DATE, col: 4, title: 'Дата прибытия', required: false})
  arrivalDate: string;
  @propertyesType({type: PropertyViewType.AUTOCOMPLETE, col: 8, required: false, title: 'Страна', crudType: 'ReferenceCountries'})
  country: CountryModel;
  @propertyesType({type: PropertyViewType.AUTOCOMPLETE, col: 12, required: true, title: 'Место проживания', crudType: 'DictionaryAnimalLivingPlaces'})
  location: LocationModel;
  address: string;
  isCurrent: boolean;
  @propertyesType({type: PropertyViewType.INPUT_STRING, title: 'Комментарии', col: 12, required: false})
  note: string;
  createdAt: string;
  updatedAt: string;

  constructor(o?: AnimalLivingPlaceInterface) {
    constructByInterface(o, this);
  }
}
