import {constructByInterface} from '../../utils/construct-by-interface';
import {UserModels} from '../user/user.models';
import {ReferenceStationInterface, ReferenceStationModel} from './reference.station.models';
import {ReferenceBusinessEntityInterface, ReferenceBusinessEntityModel} from './reference.businessEntity.models';
import {propertyesType, PropertyViewType} from '../../modules/shared/decorators/property-type.decorator';


export interface ReferenceSupervisedObjectInterface {
  id: number;
  name: string;
  kpp: string;
  address: string;
  headFullName: string;
  headOffice: string;
  comment: string;
  user: UserModels;
  telephoneNumber: string;
  activityKind: string;
  email: string;
  station: ReferenceStationInterface;
  businessEntity: ReferenceBusinessEntityInterface;
  internetConnection: boolean;
  issuesCertificates: boolean;
  pushingAvailable: boolean;
  compartment: number;
  latitude: number;
  longitude: number;
}

export class ReferenceSupervisedObjectModel implements ReferenceSupervisedObjectInterface {
  id: number;
  @propertyesType({type: PropertyViewType.INPUT_STRING, title: 'Наименование', col: 12, required: true})
  name: string;

  @propertyesType({type: PropertyViewType.INPUT_STRING, title: 'Адрес', col: 8, required: true})
  address: string;

  @propertyesType({type: PropertyViewType.INPUT_STRING, title: 'КПП', col: 4, required: true})
  kpp: string;

  @propertyesType({type: PropertyViewType.DADATA_FULL_NAME, title: 'ФИО руководителя', col: 12, required: true})
  headFullName: string;

  @propertyesType({type: PropertyViewType.INPUT_STRING, title: 'Должность', col: 4, required: true})
  headOffice: string;

  @propertyesType({type: PropertyViewType.INPUT_STRING, title: 'Номер телефона', col: 4, required: true})
  telephoneNumber: string;

  @propertyesType({type: PropertyViewType.INPUT_STRING, title: 'Email', col: 4, required: false})
  email: string;

  @propertyesType({type: PropertyViewType.INPUT_STRING, title: 'Вид осуществляемой деятельности', col: 12, required: true})
  activityKind: string;

  @propertyesType({type: PropertyViewType.INPUT_STRING, title: 'Комментарий', col: 12, required: false})
  comment: string;

  @propertyesType({type: PropertyViewType.AUTOCOMPLETE, title: 'Станции' , col: 6, required: true, crudType: 'ReferenceStation'})
  station: ReferenceStationModel;

  @propertyesType({type: PropertyViewType.AUTOCOMPLETE, title: 'Хоз. субъекты', col: 6, required: true, crudType: 'ReferenceBusinessEntity'})
  businessEntity: ReferenceBusinessEntityModel;

  @propertyesType({type: PropertyViewType.CHECK_BOX, col: 4, required: false, label: 'Работа с социальными объектами'})
  internetConnection: boolean;

  @propertyesType({type: PropertyViewType.CHECK_BOX, col: 4, required: false, label: 'Объект использует форму электронной сертификации'})
  issuesCertificates: boolean;

  @propertyesType({type: PropertyViewType.CHECK_BOX, col: 4, required: false, label: 'Разрешить выгрузку в МСХ АИС НСИ и на публичную карту'})
  pushingAvailable: boolean;

  @propertyesType({type: PropertyViewType.AUTOCOMPLETE, title: 'Пользователь', col: 12, required: false, crudType: 'User'})
  user: UserModels;

  compartment: number;
  latitude: number;
  longitude: number;



  constructor(o?: ReferenceSupervisedObjectInterface) {
    if (o) {
      constructByInterface(o, this);
    }
  }
}


