import {Injectable} from '@angular/core';
import {Actions} from '@ngrx/effects';


@Injectable()
export class ErrorEffects {

  constructor(private actions$: Actions) {
  }
}
