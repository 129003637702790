<div *ngIf="search" class="box-empty box-empty">
  <h3>
    Упс, ничего не найдено.
    <span>Попробуйте указать другой запрос</span>
  </h3>
</div>
<div *ngIf="!search" class="box-empty">
  <h3>
    {{title || 'Упс, ничего не найдено'}}
    <span *ngIf="subtitle">{{subtitle}}</span>
  </h3>
  <div class="box-empty__btn mt-10" *ngIf="addLink || buttons">
    <a *ngIf="isValidLink() && !addLinInvisible" [routerLink]="addLink" mat-button>Добавить {{elementName}}</a>
    <ng-container *ngIf="buttons && buttons.length">
      <button mat-flat-button type="button" color="primary" *ngFor="let button of buttons"
              (click)="buttonClick.emit({action: button.action})">
        {{button.title}}
      </button>
    </ng-container>
  </div>
</div>

