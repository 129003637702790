import {constructByInterface} from '../utils/construct-by-interface';

export interface GroupInterface {
  id: number;
  name: string;
  code: string;
  externalId?: number;
}

export class GroupModel implements GroupInterface {
  id: number;
  name: string;
  code: string;
  externalId?: number;

  constructor(o?: GroupInterface) {
    constructByInterface(o, this);
  }
}
