<!--<div class="container-fluid ">-->
<!--  <div *ngIf="!isEdit" class="row justify-content-lg-start">-->
<!--    <div *ngIf="backButton" class="col-2 col-lg-1">-->
<!--      <a  [routerLink]="goListUrl" [hidden]="hidden" role="button" class="btn  btn-large btn-outline-secondary mb-2">Назад</a>-->
<!--    </div>-->
<!--    <div class="col-3 col-sm-2 col-lg-1 text-left " *ngIf="cancelButton">-->
<!--      <a [mat-dialog-close]="true"  (click)="exit($event)"  class="btn  btn-large btn-outline-secondary mb-2 mr-2">Отмена</a>-->
<!--    </div>-->
<!--    <div class="col-9 col-sm-10 col-lg-11 pl-5  pl-lg-0">-->
<!--      <div class="container-fluid">-->
<!--        <div class="row  justify-content-sm-end">-->
<!--          <div class="12 col-lg-12 text-right d-inline-block">-->
<!--            <button  *ngIf="showExtraButton" type="button" (click)="isExtraButtonClick($event)" class="btn btn-large btn-primary  mb-2 mr-0 mr-sm-4 mr-lg-4 "-->
<!--                     [class.btn-loader]="getPatchLoading$ | async">{{ExtraButtonName}}-->
<!--            </button>-->
<!--            <button type="submit" (click)="isButtonClick($event)"  class="btn btn-large btn-primary mb-2"-->
<!--                    [class.btn-loader]="getLoading$ | async"-->
<!--                    [disabled]="disabled">{{saveButtonName}}-->
<!--            </button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div *ngIf="cancelButton">-->
<!--    <app-button-close></app-button-close>-->
<!--  </div>-->

<!--</div>-->



<!--<div class="form-footer" *ngIf="!isEdit">-->
<!--  <button type="submit" (click)="isButtonClick($event)" class="btn-st btn-st&#45;&#45;right"-->
<!--          [class.btn-loader]="getLoading$ | async"-->
<!--          [disabled]="disabled">{{saveButtonName}}-->
<!--  </button>-->
<!--  <div class="form-footer" *ngIf="backButton">-->
<!--  <a [routerLink]="goListUrl" [hidden]="hidden" class="btn-st btn-st&#45;&#45;left btn-st&#45;&#45;gray">Назад</a>-->
<!--    <div class="'form-footer" *ngIf="showExtraButton">-->
<!--      <button type="button" (click)="isExtraButtonClick($event)" class="btn-st btn-st&#45;&#45;right"-->
<!--              [class.btn-loader]="getPatchLoading$ | async">{{ExtraButtonName}}-->
<!--      </button>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="form-footer" *ngIf="cancelButton">-->
<!--    <a [mat-dialog-close]="false"  class="btn-st btn-st&#45;&#45;left btn-st&#45;&#45;gray">Отмена</a>-->
<!--    <app-button-close></app-button-close>-->
<!--  </div>-->
<!--</div>-->


<!--<div class="form-footer" *ngIf="isEdit">-->
<!--  <button *ngIf="showSaveButton" type="submit" (click)="isButtonClick($event)" class="btn-st btn-st&#45;&#45;right"-->
<!--          [class.btn-loader]="getPatchLoading$ | async">{{saveButtonName}}-->
<!--  </button>-->
<!--    <button *ngIf="showExtraButton" type="button" (click)="isExtraButtonClick($event)" class="btn-st btn-st&#45;&#45;right"-->
<!--            [class.btn-loader]="getPatchLoading$ | async">{{ExtraButtonName}}-->
<!--    </button>-->
<!--  <a [routerLink]="goListUrl" [hidden]="hidden" class="btn-st btn-st&#45;&#45;right btn-st&#45;&#45;gray">Отмена</a>-->
<!--  <a *ngIf="removeAble" [hidden]="hidden" href="#remove-reference" data-fancybox-->
<!--     class="btn-st btn-st&#45;&#45;left btn-st&#45;&#45;tr-red btn-st&#45;&#45;uppercase btn-st&#45;&#45;bold">{{removeButtonName}}</a>-->

<!--  <div class="popup-hide">-->
<!--    <div id="remove-reference" class="popup-close-id popup-wr">-->
<!--      <ngx-loading [show]="loadingRemove$|async"-->
<!--                   [config]="{fullScreenBackdrop: true,  primaryColour: '#000000', secondaryColour: '#000000'}"></ngx-loading>-->
<!--      <div class="popup-wr__head">-->
<!--        Вы точно хотите удалить-->
<!--        элемент?-->
<!--        <div class="popup-wr__head-comment">Действие необратимо</div>-->
<!--      </div>-->
<!--      <div class="popup-wr__footer">-->
<!--        <a href="" class="btn-st btn-st&#45;&#45;left btn-st&#45;&#45;gray" data-fancybox-close>Отмена</a>-->
<!--        <a href="" class="btn-st btn-st&#45;&#45;right btn-st&#45;&#45;red" (click)="remove($event)">Удалить</a>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<!--<div class="container-fluid ">-->
<!--  <div *ngIf="!isEdit" class="row justify-content-lg-start">-->
<!--    <div *ngIf="backButton" class="col-2 col-lg-1">-->
<!--      <a  [routerLink]="goListUrl" [hidden]="hidden" role="button" class="btn  btn-large btn-outline-secondary mb-2">Назад</a>-->
<!--    </div>-->
<!--    <div class="col-3 col-sm-2 col-lg-1 text-left " *ngIf="cancelButton">-->
<!--      <a [mat-dialog-close]="true"  (click)="exit($event)"  class="btn  btn-large btn-outline-secondary mb-2 mr-2">Отмена</a>-->
<!--    </div>-->
<!--    <div class="col-9 col-sm-10 col-lg-11 pl-5  pl-lg-0">-->
<!--      <div class="container-fluid">-->
<!--        <div class="row  justify-content-sm-end">-->
<!--          <div class="12 col-lg-12 text-right d-inline-block">-->
<!--            <k-button  *ngIf="showExtraButton" color="primary" (click)="isExtraButtonClick($event)"-->
<!--                     [class.btn-loader]="getPatchLoading$ | async">{{ExtraButtonName}}-->
<!--            </k-button>-->
<!--            <k-button  (click)="isButtonClick($event)"  color="primary"-->
<!--                    [class.btn-loader]="getLoading$ | async"-->
<!--                    [disabled]="disabled">{{saveButtonName}}-->
<!--            </k-button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div *ngIf="cancelButton">-->
<!--    <app-button-close></app-button-close>-->
<!--  </div>-->

<!--</div>-->
<k-container fluid>
  <ng-template #templateRef>
    <k-row *ngIf="!isEdit" justify="space-between">
      <ng-template #row>
        <k-col *ngIf="backButton">
          <ng-template #col>
            <a [routerLink]="goListUrl" [hidden]="hidden">Назад</a>
          </ng-template>
        </k-col>
        <k-col *ngIf="cancelButton">
          <ng-template #col>
            <a [mat-dialog-close]="true"  (click)="exit($event)">Отмена</a>
          </ng-template>
        </k-col>
        <k-col class="text-right">
          <ng-template #col>
            <k-button  (click)="isButtonClick($event)"  color="primary" name="{{saveButtonName}}"
                       [class.btn-loader]="getLoading$ | async"
                       [disabled]="disabled">
            </k-button>
          </ng-template>
        </k-col>
      </ng-template>
    </k-row>
    <k-row *ngIf="cancelButton">
      <ng-template #row>
        <app-button-close></app-button-close>
      </ng-template>
    </k-row>
  </ng-template>
</k-container>


<k-container fluid>
  <ng-template #templateRef>
    <k-row *ngIf="isEdit" justify="space-between">
      <ng-template #row>
        <k-col>
          <ng-template #col>
            <a [routerLink]="goListUrl" [hidden]="hidden">Отмена</a>
          </ng-template>
        </k-col>
        <k-col *ngIf="removeAble">
          <ng-template #col>
            <k-button (click)="remove($event)" [hidden]="hidden" color="primary" name="{{removeButtonName}}"></k-button>
          </ng-template>
        </k-col>
        <k-col class="text-right" *ngIf="showSaveButton">
          <ng-template #col>
            <k-button  (click)="isButtonClick($event)"
                       color="primary"
                       name="{{saveButtonName}}"
                       [class.btn-loader]="getLoading$ | async"
                       [disabled]="disabled">
            </k-button>
          </ng-template>
        </k-col>
      </ng-template>
    </k-row>
  </ng-template>
</k-container>



