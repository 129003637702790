import {constructByInterface} from '../utils/construct-by-interface';
import {propertyesType, PropertyViewType} from '../modules/shared/decorators/property-type.decorator';

export interface SettingInterface {
  key: string;
  value: string;
  id?: number;
}

export class SettingModel implements SettingInterface {
  @propertyesType({type: PropertyViewType.INPUT_STRING,  title: 'Ключ', col: 12, required: true})
  key: string;
  @propertyesType({type: PropertyViewType.INPUT_STRING,  title: 'Значение', col: 12, required: true})
  value: string;
  id: number;

  constructor(o?: SettingInterface) {
    constructByInterface(o, this);
  }
}
