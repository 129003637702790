import {constructByInterface} from '../../utils/construct-by-interface';
import {VaccinationInterface, VaccinationModel} from '../vaccination/vaccination.model';
import {VaccineInterface, VaccineModel} from './vaccine.model';
import {propertyesType, PropertyViewType} from '../../modules/shared/decorators/property-type.decorator';

export interface VaccineSeriesInterface {
  id: number;
  parent: VaccineSeriesInterface;
  children: Array<VaccineSeriesInterface>;
  serialNumber: string;
  produced: string;
  expires: string;
  vaccine: VaccineInterface;
  vaccinations: Array<VaccinationInterface>;
  isInvalid: boolean;
}

export class VaccineSeriesModel implements VaccineSeriesInterface {
  id: number;
  parent: VaccineSeriesModel;
  children: Array<VaccineSeriesModel>;
  @propertyesType({type: PropertyViewType.INPUT_STRING, title: 'Серийный номер', col: 12, required: true})
  serialNumber: string;
  @propertyesType({type: PropertyViewType.INPUT_STRING, title: 'Произведен', col: 12, required: true})
  produced: string;
  @propertyesType({type: PropertyViewType.INPUT_STRING, title: 'Срок действия', col: 12, required: true})
  expires: string;
  @propertyesType({type: PropertyViewType.INPUT_STRING, title: 'Вакцина', col: 12, required: true})
  vaccine: VaccineModel;
  @propertyesType({type: PropertyViewType.INPUT_STRING, title: 'Вакцинации', col: 12, required: true})
  vaccinations: Array<VaccinationModel>;
  isInvalid: boolean;

  constructor(o?: VaccinationInterface) {
    constructByInterface(o, this);
  }
}
