import {constructByInterface} from '../../utils/construct-by-interface';
import {ReferenceManufacturerModel} from './reference.manufacturer.models';
import {propertyesType, PropertyViewType} from '../../modules/shared/decorators/property-type.decorator';

export interface ReferenceCountriesInterface {
  id: number;
  name: string;
  deleted: boolean;
  sort: number;
}

export class ReferenceCountriesModel implements ReferenceCountriesInterface {
  id: number;
  @propertyesType({type: PropertyViewType.INPUT_STRING, title: 'Наименование', col: 12, required: true})
  name: string;
  deleted: boolean;
  sort: number;


  constructor(o?: ReferenceCountriesInterface) {
    constructByInterface(o, this);
  }
}
